<template>
  <div>
    <SageAccountCodeModal
          :show-modal="showAccountCodeModal"
          :selected-artist="selectedArtist"
          :venueBrandId="selectedVenueBrandId"
          :code-prop="selectedSageCode"
          @showAccountCodeModal="value => showAccountCodeModal = value"
          @artistAccountCodeUpdated="artistAccountCodeUpdated"
  />

  <div class="lg:ml-4">
    <InlineNotification class="intro" Heading="Invoices" DismissId="VenueInvoices">
      <template slot="body">
        <p>
          View and download all past invoices. Download them in PDF format, or export them
          to a CSV file.
        </p>
      </template>
    </InlineNotification>

    <div class="max-w-4xl filters">
      <Accordian heading="Filter" class="mx-4 lg:mx-0">
        <template #contents>
          <div class="grid grid-flow-row grid-cols-2 gap-4 pt-0 pr-0 my-4 mt-2 form">
            <div class="col-span-2">
              <label>Venue</label>
              <TRichSelect
                class="trichselect thin"
                required
                id="venue-selection"
                ref="venuepicker"
                name="venue-selection"
                placeholder="Filter by venue"
                :options="compVenues"
                v-model="selectedVenue"
                value-attribute="id"
                text-attribute="name"
              />
            </div>
            <div class="hidden">
              <label> Status </label>
              <TSelect
                v-model="invoiceStatus"
                class="trichselect"
                :options="[
                  { value: 'all', text: 'All' },
                  { value: 'paid', text: 'Paid' },
                  { value: 'unpaid', text: 'Unpaid' },
                ]"
              ></TSelect>
            </div>
            <div>
              <label>Raised From</label>
              <TDatePicker v-model="raisedFrom"></TDatePicker>
            </div>
            <div>
              <label>Raised To</label>
              <TDatePicker v-model="raisedTo"></TDatePicker>
            </div>
            <InputText
              name="invoicenumber"
              v-model="invoiceNumber"
              ref="invoicenumber"
              class="mt-0"
              type="number"
              prefix="GP-"
              label="Invoice number"
              placeholder="00001"
            />

            <div class="justify-start col-span-2 text-left">
              <a class="inline-block mr-4 bg-indigo-500 cta" @click="filter()">Filter</a>
            </div>
          </div>
        </template>
      </Accordian>
      <div class="flex justify-end"></div>
    </div>

    <div class="flex flex-col gap-8 py-4 mx-4 filter-ctas lg:mx-0 lg:flex-row">

              <GPButton class=" !bg-indigo-500 py-3"
                        @click.native="downloadInvoices(true)"
                        :disabled="downloadSelectedLoading"
                        :loading="downloadSelectedLoading"
                    >Download selected invoices ({{ selectedInvoices.length }})
              </GPButton>

              <GPButton class="inline-block"
                        @click.native="downloadInvoices(false)"
                        :disabled="downloadAllLoading"
                        :loading="downloadAllLoading"
                    >Download all invoices
              </GPButton>

    </div>
    <div class="filter-ctas">
      <div class="flex flex-col mx-4 lg:mx-0 lg:flex-row">
        <GPButton 
					@click.native="exportCSV()" 
					:disabled="loadingExports"
          :loading="loadingExports"
          color="grey"
					size="lg"
          class="mt-4"
				>Export to CSV ({{ excludeCSV }})</GPButton>
      </div>
    </div>
    
    <div  class="flex flex-col items-center justify-between max-w-5xl mx-4 mt-4 filter-ctas lg:mx-0 lg:flex-row">
      <div class="flex flex-row my-4">
        <t-toggle name="archived_invoice" v-model="excludeArchived" @change="loadData" class="mr-4"/>
        <label>Exclude Archived Invoices</label>
      </div>
      <div class="flex items-center">
        <span class="mr-4">Sort by</span>
        <div>
          <TSelect class="max-w-sm trichselect thin" v-model="invoice_sortby" @change="updateSort()"
            :options="[
            { value: '0', text: 'By gig date (newest to oldest)' },
            { value: '1', text: 'By gig date (oldest to newest)' },
            { value: '2', text: 'By sign-off date (newest to oldest)' },
            { value: '3', text: 'By sign-off date (oldest to newest)' },
            { value: '4', text: 'By venue (A-Z)' },
            { value: '5', text: 'By venue (Z-A)' },
            { value: '6', text: 'By artist (A-Z)' },
            { value: '7', text: 'By artist (Z-A)' },
            ]">
          </TSelect>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-4 results" v-if="results && results.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="max-w-5xl overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full text-left divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative px-6 py-3">
                    <input ref="selectall" type="checkbox" @change="selectAll($event.target.checked)" />
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase marker:"
                  >
                    Artist
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase"
                  >
                    Venue
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-gray-500 uppercase"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  class="relative"
                  v-for="(bid, index) in results"
                  :key="index"
                  :row="bid"
                >
                  <td class="p-6">
                    <input
                      ref="check"
                      v-bind:id="bid.id"
                      type="checkbox"
                      @change="toggleOffer($event.target.checked, bid)"
                    />
                  </td>
                
                  <td class="w-1/6 p-6" :class="{'px-1': usesSageAccountCodes}">
                    <router-link :to="'/gigs/' + bid.gig.id + '/invoices'" class="block"
                      >View bookings</router-link
                    >
										<a 
											class="text-gp_pink-default-500" v-if="!bid.signed_off_at"
											@click="editBidFee(bid)"
										>Amend fee</a>
                    <a
                      @click="downloadInvoice(bid)"
                      class="block mt-2 text-gp_pink-default-500"
                      >Download</a
                    >
                    <a v-show="hasTagAccess && isGenre"
											class="block mt-2 text-gp_pink-default-500"
											@click="openUpdateTagsModal(index, bid.gig)"
										>Manage tags</a>
                    <a
                        @click="updateArtistSageCode(bid.artist, bid)"
                        class="block mt-2 text-gp_pink-default-500"
                        v-if="usesSageAccountCodes && bid.venue.brand !== null"
                        >Update Sage Code</a
                    >
                    <a
                        v-else
                        @click="archiveInvoiceLocal(bid)"
                        class="block mt-2 text-gp_pink-default-500"
                        >Click to archive</a
                    >

                  </td>
                  <td class="items-center justify-center h-full p-6 mx-2 text-left">
                    <span class="block">
                        {{ bid.artist.name }}
                        <span v-show="bid.artist.deleted_at" class="text-xs">[Artist deleted]</span>
                    </span>
                    <span class="block mt-2">{{bid.invoice.currency.symbol}}{{ bid.fee }}</span>

                    <div v-if="bid.artist_sage_account_code != null && usesSageAccountCodes && bid.venue.brand !== null" class="flex gap-2 mt-2">
                      <SageAccountCodeBadge :width="'19px'" :height="'19px'" :color="'rgba(241, 113, 144)'"></SageAccountCodeBadge>
                      <span class="leading-[22px]">{{ bid.artist_sage_account_code }}</span>
                    </div>
                    <div v-else-if="usesSageAccountCodes && bid.venue.brand !== null" class="flex gap-2 mt-2">
                      <SageAccountCodeBadge :width="'19px'" :height="'19px'"></SageAccountCodeBadge>
                      <span class="leading-[22px]"><strong>UNKNOWN</strong></span>
                    </div>

                  </td>
                  <td class="p-6">
                    <strong>{{ bid.venue.name }}</strong>
                    <span class="block">{{ bid.gig.name }}</span>
                    <span class="block">{{ fullDate(bid.gig.start) }}</span>

                    <div class="flex items-center my-3 space-x-3">
                      <div v-if="bid.gig.custom_tags.length > 0" class="flex items-center justify-center px-3 py-1 space-x-2 bg-black rounded-3xl ">
                        <component :is="icons[firstCustomTagIconName(bid.gig.custom_tags[0])]" class="w-4 h-4" :style="{ color: firstCustomTagIconColour(bid.gig.custom_tags[0]) }"/>
                        <span class="text-xs text-white">{{ firstCustomTagName(bid.gig.custom_tags[0]) }}</span>
                      </div>
                      <div>
                        <span class="relative inline-flex flex-col items-center group" v-if="bid.gig.custom_tags.length > 0">
                          <span v-if="bid.gig.custom_tags.length > 1"
                          class="text-sm font-medium underline cursor-pointer underline-offset-2"
                          >+{{ bid.gig.custom_tags.length - 1 }} more</span>
                          <div class="absolute bottom-0 z-10 flex-col items-center hidden mb-6 group-hover:flex">
                          <span class="w-48 p-4 bg-black rounded-sm shadow-lg">
                            <ul class="list-none">
                            <li v-for="tag in bid.gig.custom_tags" :key="tag.id" class="flex items-center space-x-4 space-y-1 text-white">
                              <component :is="icons[tag.icon]" class="w-5 h-5" :style="{ color: tag.icon_colour }"/>
                              <span>{{ tag.name }}</span>
                            </li>
                            </ul>
                          </span>
                          <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
                          </div>
                        </span>
                      </div>
                    </div>

                  </td>
                  <td class="flex flex-col items-start justify-start gap-2 p-6">

                    <span>
                      <span class="font-bold">Raised</span> {{ formatShortDate(bid.invoice.date_raised)  }}
                    </span>
                    <div v-if="bid.signed_off_by !== null">
                      <span>
                        <span class="font-bold">Sign-off by</span><br/>{{ bid.signed_off_by.name }}
                      </span>
                      <span><br>
                      <span class="font-bold">on</span>
                      {{ bid.signed_off_at }}</span>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>

            <template>
              <Pagination
                  @changePage="(n) => paginate_currPage = n"
                  :paginate_from="paginate_from"
                  :paginate_to="paginate_to"
                  :paginate_total="paginate_total"
                  :paginate_currPage="paginate_currPage"
                  :paginate_lastPage="paginate_lastPage"
              ></Pagination>
              </template>


          </div>
        </div>
      </div>
    </div>
    <div class="pt-4" v-else-if="invoices && results.length == 0">
      <p>There are no invoices to display, please refine your filters.</p>
    </div>
    <div class="pt-4" v-else>
      <p>Loading your invoices.</p>
    </div>
    <InvoiceGeneration ref="invoice" :invoiceItems="invoiceItems" />
    <AmendCustomGigTag ref="updateGigTags" v-on:updatedTag="updatedTags($event)"/> 
		<template v-if="selectedBid">
			<AmendBidFee 
				:is-open="showAmendBidFeeModal"
				:bid="selectedBid"
				@closed="cancelModal"
				@updated="onBidFeeUpdated"
			/>
		</template>

    <div v-show="selectedInvoices.length > 0" class="justify-between action-bar">  
      <p class="px-8 font-light uppercase text-gp_pink-default-500 font-gp_pink-default-500">Invoices Selected</p>
      <div class="flex flex-col items-end w-full space-x-2 space-y-2 lg:flex-row lg:w-1/2"> 
        <button class="invisible text-white bg-black cta icon">Download Invoices</button>
        <button @click="archiveSelected()" class="bg-indigo-600 cta icon" v-if="excludeArchived"><ArchiveIcon class="mr-2" />Archive Invoices ({{ selectedInvoices.length }})</button>
      </div>
    </div>

  </div>
  
  </div>

</template>

<style type="text/css" scoped>
table {
  table-layout: auto;
  width: 1000px;
}

table td {
  min-width: min-content;
}
</style>

<script type="text/javascript">

import  * as icons from "@vue-hero-icons/outline" 
import { apiComputed, apiMethods } from '@/state/helpers.js'
import { ArchiveIcon } from "@vue-hero-icons/outline"
import fullDate from  '@/utils/format-date-full.js'
import timeAgo from  '@/utils/format-date-timeago.js'
import InlineNotification from '../../components/notifications/inline.vue';
import { TRichSelect, TSelect } from 'vue-tailwind/dist/components';
import InputText from '../../components/form/InputText.vue';
import TDatePicker from 'vue-tailwind/dist/t-datepicker';
import Accordian from '../../components/accordian/Accordian.vue';
import InvoiceGeneration from '../../components/invoice/wrapper.vue';
import NProgress from 'nprogress'
import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'
import normalizeDate from '../../utils/normalizeDate.js';
import SageAccountCodeModal from '../../components/modal/invoices/SageAccountCodeModal.vue';
import SageAccountCodeBadge from '../../components/iconsets/SageAccountCodeBadge.vue';
import AmendCustomGigTag from '../../components/modal/gigs/AmendCustomTags.vue';
import {
  TToggle
} from 'vue-tailwind/dist/components';
import Pagination from "@/components/elements/Pagination.vue";
import GPButton from '../../components/ui/buttons/Button.vue';
import {mapState} from "vuex";
import client from '@/utils/client.js'

const SORT_KEY = "venue_invoice_sort";

const { DateTime } = require("luxon");


export default {
    name: "Invoices",
  components:
  {
    Pagination,
    InvoiceGeneration,
    InlineNotification,
    TRichSelect,
    InputText,
    TDatePicker,
    TSelect,
    Accordian,
		AmendBidFee,
    TToggle,
    ArchiveIcon,
    GPButton,
    SageAccountCodeModal,
    SageAccountCodeBadge,
    AmendCustomGigTag
},
  data() {
    return {
      icons: icons,
      loadingExports: false,
      paginate_from: null,
      paginate_to: null,
      toggleArchived: null,
      paginate_total: null,
      paginate_currPage: 1,
      paginate_lastPage: null,
      invoice_sortby: "0",
      excludeArchived: true,
      selectedVenue: null,
      invoiceNumber: '',
      invoiceStatus: 'all',
      raisedFrom: null,
      raisedTo: null,
      selectedInvoices: [],
      invoiceItems: [],
      results: [],
      invoices: null,
      compVenues: null,
			showAmendBidFeeModal: false,
			selectedBid:null,
      downloadAllLoading: false,
      downloadSelectedLoading: false,
      user: this.$store.state.user,
      usesSageAccountCodes: false,
      showAccountCodeModal: false,
      selectedArtist: null,
      selectedVenueBrandId: null,
      selectedSageCode: null,
      selectedIndex: null
    }
  },
  watch: {
    toggleArchived(val){
      this.paginate_currPage = 1;
      this.excludeArchived = val;
      this.loadData();
    },
    paginate_currPage() {
      window.scrollTo(0,0);
      this.loadData();
    },
    user: {
      handler: function (val) {
        if (val.userData && val.userData.brand) {
          this.usesSageAccountCodes = Boolean(val.userData.brand.use_sage_account_code);
        }
        
      },
      deep: true,
    },
  },
  computed: {
    ...apiComputed,
    ...mapState({
      userRoles: (state) => state.user.rolesPermissionsSlugs,
    }),
    hasTagAccess()
    {
        if (this.userRoles && this.userRoles.length > 0) {
            return this.userRoles[0] == 'full-access';
        } else {
            return null; 
        }
    },
    userData(){
      return this.$store.getters['user/userData']
    },
    isGenre() {
      return this.userData && this.userData.email.includes('genremusic')
    },
		getArtistsName(){
			return (gig) => gig && gig.artist ? gig.artist.name : "no artists details"
		},
    excludeCSV(){
      return this.selectedInvoices.length === 0 ? 'All' : this.selectedInvoices.length;
    },
  },
  mounted(){

    if (!document.getElementById('pdfjs')) {
      var scriptTag = document.createElement("script");
      scriptTag.src = "https://raw.githack.com/eKoopmans/html2pdf/master/dist/html2pdf.bundle.js";
      scriptTag.id = "pdfjs";
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }

    this.excludeArchived = true; 
    let localSort = localStorage.getItem(SORT_KEY);

    if (localSort){
      this.invoice_sortby = localSort;
    } else {
      this.loadData();
    }

    client
      .get('venues-filter-list')
      .then((response) => {
        this.compVenues = response.data.data;
      })
  },
  methods: {
    firstCustomTagName(val){
      return val.name
    },
    firstCustomTagIconName(val){
      return val.icon
    },
    firstCustomTagIconColour(val){
      return val.icon_colour
    },
    returnTags(val) {
      return val.custom_tags;
    },
    openUpdateTagsModal(index, gig) {
      this.selectedIndex = index;
      this.$refs.updateGigTags.updateGigTag(gig);
    },
    updatedTags(event) {
      this.results[this.selectedIndex].gig.custom_tags = event;
    },
    formatShortDate($date){
      let normalised = normalizeDate($date);

      let datetime = DateTime.fromFormat(normalised, 'yyyy/MM/dd HH:mm');

      return datetime.toFormat('yyyy-MM-dd');
    },
    loadData(){
      this.filter();
    },
    exportCSV(){
      NProgress.start();
      this.loadingExports = true;

      if (this.selectedInvoices.length) {
        this.bookingExport(this.selectedInvoices.map(m => m.id))
            .then(() => {
              NProgress.done();
              this.loadingExports = false;
            });
      } else {
        let request = {
          excludeArchived: this.excludeArchived,
          page: this.paginate_currPage,
          venue_id: this.selectedVenue,
          date_from: this.raisedFrom,
          date_to: this.raisedTo, 
          invoice_number: this.invoiceNumber,
          invoice_sortby: this.invoice_sortby,
          pagination: false
        }
        this.getFilteredInvoices({request: request, useTrashedArtists: true}).then((resp) => {
          this.bookingExport(resp.data.map(m => m.id))
            .then(() => {
              NProgress.done();
              this.loadingExports = false;
            });
        })
      }
    },
    selectAll($checked){
        this.selectedInvoices = [];
        this.$refs.check.forEach((c) => {
          c.checked = $checked;

          let $bid = this.invoices.find(bid => bid.id == c.id);

          this.toggleOffer($checked, $bid);
        });
    },
    async archiveInvoiceLocal(bid) {
      NProgress.start();
      try {
        const {data} = await this.archiveInvoice(bid);
        this.updateInvoice(data, bid)
        this.$notify('Invoice archived');
      } catch (err) {
        this.$notify('Error archiving invoice');
      } finally {
        NProgress.done();
      }
    },
    updateArtistSageCode(artist, bid) {
      this.selectedArtist = artist;
      this.selectedVenueBrandId = bid.venue.brand.id;
      this.selectedSageCode = bid.artist_sage_account_code ? bid.artist_sage_account_code : null;
      this.showAccountCodeModal = true;
    },
    archiveSelected(){
      NProgress.start();
      let bidsID = this.selectedInvoices.map(bid => bid.id);

      this.archiveAllSelected(bidsID)
          .then(() => {             
            var clist = document.getElementsByTagName("input");
            for (var i = 0; i < clist.length; ++i) { 
              clist[i].checked = false; 
            }
            this.selectedInvoices = [];

            this.$notify("Invoices successfully archived");
            NProgress.done();
      
            this.filter();
          })
          .catch((error) => {
            this.$notify(error.response.data.message);
          });
    },
    updateInvoice(data, bid) {
      this.results = this.results.map((item) => {
        if (item.id === bid.id) {
          item.invoice.archived_at = data.data.invoice.archived_at;
        }
        return item;
      });
      if (this.excludeArchived) {
        this.results = this.results.filter(b => b.id !== bid.id);
      }
    },
    toggleOffer: function(isOn, $bid){

      if (isOn)
      {
        this.selectedInvoices.push($bid);
      }
      else
      {
        let removeIndex = this.selectedInvoices.indexOf($bid);
        if (removeIndex !== -1){
          this.selectedInvoices.splice(removeIndex, 1);
        }
      }
    },
    downloadInvoice($bid){

      this.getInvoice($bid.id).then((resp) => {
        this.invoiceItems = [resp];
        this.$refs.invoice.generate();
      });

    },
    downloadInvoices($only_selected){

      if ($only_selected)
      {
        this.downloadSelectedLoading = true;
        this.invoiceItems = this.selectedInvoices;
      }
      else { 
        this.downloadAllLoading = true;
        this.invoiceItems = this.getResults();
      }



      if (this.invoiceItems && this.invoiceItems.length > 0) {
        window.scrollTo(0, 0);
        setTimeout(() => {
          let pages = document.querySelectorAll('#pdf_content > div');
          // let item = window.html2pdf().from(element);

          let options = {
            html2canvas:  { scale: 1, scrollY: 0 }
          };

           let doc = window.html2pdf().set(options).from(pages[0]).toPdf()
      for (let j = 1; j < pages.length; j++) {
        doc = doc.get('pdf').then(
          pdf => { pdf.addPage() }
        ).from(pages[j]).toContainer().toCanvas().toPdf()

        this.pdf_generate_count += 1;
      }

           doc.save().thenExternal(() => {
            this.downloadAllLoading = false;
            this.downloadSelectedLoading = false;
            this.pdf_generate_count = 0;
           });
        }, 2000);
      }
    },
    filter(){

      NProgress.start();

      let check = this.$refs.check;
      
      if (check) {
        check.forEach((c) => {
          c.checked = false;
        });
      }

      let selectAll = this.$refs.selectAll;
      if (selectAll) {
        selectAll.checked = false;
      }

      this.selectedInvoices = [];

      let request = {
        excludeArchived: this.excludeArchived,
        page: this.paginate_currPage,
        venue_id: this.selectedVenue,
        date_from: this.raisedFrom,
        date_to: this.raisedTo, 
        invoice_number: this.invoiceNumber,
        invoice_sortby: this.invoice_sortby,
        pagination: true
      }
  

      this.getFilteredInvoices({request: request, useTrashedArtists: true}).then((resp) => {
        this.invoices = resp.data;
        this.paginate_to = resp.to;
        this.paginate_from = resp.from;
        this.paginate_total = resp.total;
        this.paginate_lastPage = resp.last_page;
        this.paginate_currPage = resp.current_page; 
      }).finally(() => {
        this.getResults(); 
        NProgress.done();
      });
    },
    ...apiMethods,
    fullDate,
    timeAgo,
    updateSort(){
      localStorage.setItem(SORT_KEY, this.invoice_sortby);
      this.filter();
    },
    getResults() {

      if (this.invoices != null){ 
        this.results = this.invoices;  
        return this.invoices;
      }
 
    },
		cancelModal(){
			this.showAmendBidFeeModal = false;
		},
		editBidFee($bid){
			this.selectedBid = $bid;
			this.showAmendBidFeeModal = true;
		},
		onBidFeeUpdated($bid){
			this.selectedBid = $bid;
			this.cancelModal();
			const selectedBidIndex = this.results.findIndex(r => r.id === this.selectedBid.id);
			if(selectedBidIndex >= 0){
				this.results[selectedBidIndex].fee = this.selectedBid.fee
			}
		},
    artistAccountCodeUpdated(){
      this.$notify('Sage code updated');
    }
  },
};
</script>
